import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const IndexPage = () => (
  <Layout>
    <SEO title="astrolab" />
    <div className="home">
      <header style={{
        // "display": "flex",
        // "flexDirection": "column",
        // "alignItems": "center"
      }}>
        <h1 style={{
        "lineHeight": "1.2em"
        }}>
          astrolab
        </h1>
      </header>
    </div>
  </Layout>
)

export default IndexPage
